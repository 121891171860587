class NeoronWidget {
    async init() {
      const script = await document.createElement("script")
      script.src =
        "https://neoron-widget-prod.web.app/neoron-widget.js"
      script.async = true
      document.body.appendChild(script)
        
      if(!document.getElementById('bot')){
        const neoron = await document.createElement("neoron-widget")
        neoron.id = 'bot';
        neoron.setAttribute('bot_id', '7ba72be6-3882-4523-b263-65edc1f0c264')
        await document.body.appendChild(neoron)      
      }

    }
  }
  
  export default new NeoronWidget()